import { VisuallyHidden, Link } from '@overdose/components'
import classNames from 'classnames'
import { getImageSrc } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { Price } from '../Price'
import { ProductImage } from '../ProductImage'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './ProductMiniCard.module.css'
import { ProductMiniCardProps } from './ProductMiniCard.types'

export const ProductMiniCard = ({
  finalPrice,
  sku,
  title,
  regularPrice,
  thumbnail,
  url,
  objectID,
}: ProductMiniCardProps) => {
  const { sendClickEvent } = useAlgoliaSearchInsignts()
  return (
    <button
      type='button'
      className={classNames(styles.card, 'flex gap-4 relative')}
      onClick={() => {
        sendClickEvent([{ title, url, listId: objectID }])
      }}>
      <div
        className={classNames(
          styles.image,
          'relative overflow-hidden aspect-square bg-background-thumb rounded-full'
        )}>
        {thumbnail && (
          <ProductImage
            title={title}
            src={getImageSrc(thumbnail?.src, '140')}
            addSrcSet={false}
            alt={thumbnail.altText}
            height={thumbnail?.height}
            width={thumbnail?.width}
            className='object-contain h-full w-full'
            sizes='(max-width: 767px) 124px, 140px'
          />
        )}
      </div>
      <div className='flex flex-col justify-center text-left'>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegularBold}
          className={styles.title}>
          {title}
        </Typography>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegular}
          className='mt-2 text-primary-muted'>
          {sku}
        </Typography>
        <Price
          tag={TypographyTag.div}
          finalPrice={finalPrice}
          regularPrice={regularPrice}
          finalPriceClassName='leading-4 md:text-lg md:leading-6'
          regularPriceClassName='leading-4 md:text-lg md:leading-6'
          showSave={false}
          className='mt-4'
        />
      </div>
      {url ? (
        <Link
          to={url}
          title={`${title} details`}
          className='absolute inset-0'
          data-id='viewItemButton'>
          <VisuallyHidden>{title} details</VisuallyHidden>
        </Link>
      ) : (
        <VisuallyHidden>{title} details</VisuallyHidden>
      )}
    </button>
  )
}
